<template>
  <div>
    <NuxtLayout name="default">
      <LcTabs
        v-bind="{ tabs, value }"
        adaptive-height
        trigger="manual"
        @update:value="navigateToTab"
      >
        <template #tab-panel="{ tab }">
          <NuxtPage :name="tab.name" :visible="tab.name === value" />
        </template>
      </LcTabs>
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
import { LcTabs } from '@lottocom/frontend-components'

const router = useRouter()
const { activeTabs: tabs } = await useMyOrders()

const value = computed(() => String(router.currentRoute.value.name))

const navigateToTab = (name?: string) => {
  const nextTab = tabs.value.find((tab) => tab.name === name)
  if (!nextTab) return

  navigateTo(nextTab)
}
</script>
